import App from './App.vue';
import router from './router';
import { createApp } from 'vue';
import { createPinia } from 'pinia';

import '@/plugins/vee-validate';
import 'flatpickr/dist/flatpickr.min.css';

const pinia = createPinia();

createApp(App)
    .use(pinia)
    .use(router)
    .mount('#app');
