import { useCookies } from "vue3-cookies";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const { cookies } = useCookies();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/components/general/MainLayout.vue'),
        children: [
            {
                path: '403',
                name: 'AccessDenied',
                meta: {
                    title: 'Access Denied',
                },
                component: () => import('@/views/AccessDenied.vue'),
            },
            {
                path: '/transactions',
                name: 'transactions',
                component: () => import('@/views/pages/Transaction/Transaction.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/pages/User/User.vue'),
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/pages/Home.vue'),
            },
            {
                path: 'user/manage/:id?',
                name: 'user-manage',
                component: () => import('@/views/pages/User/UserManage.vue'),
            }
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    let token = cookies.get('token');

    if (to.name !== 'login' && !token) {
        return next({ name: 'login' })
    } else {
        if (token && to.name === 'login') {
            return next('/')
        } else {
            return next()
        }
    }
});

export default router;