<template>
  <div class="notification">
    <div
        class="close-icon-body"
        :class="typeClass"
        @click="$emit('close', notificationItem.id)"
    >
      <a-icon/>
    </div>

    <div
        class="notification-content"
        :class="[typeClass, { 'long-text': isLongText }]"
        @focus="$emit('hover', notificationItem.id)"
        @blur="$emit('hover-leave', notificationItem.id)"
    >
      <div class="icon-body" :class="typeClass">
        <a-icon/>
      </div>
      <div class="text">{{ notificationItem.text }}</div>
      <div
          class="close-icon-responsive"
          @click="$emit('close', notificationItem.id)"
      >
        <a-icon/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed,} from 'vue';
import {Notification, NotificationType} from './types';

interface NotificationProps {
  notificationItem: Notification,
}

interface NotificationEmits {
  (e: 'close', itemID: string): void

  (e: 'hover', itemID: string): void

  (e: 'hover-leave', itemID: string): void
}

defineEmits<NotificationEmits>();

const props = defineProps<NotificationProps>();
const typeClass = computed(() => {
  switch (props.notificationItem.type) {
    case NotificationType.ERROR:
      return 'error-message';
    case NotificationType.SUCCESS:
      return 'success-message';
    default:
      return '';
  }
});

const isLongText = computed(() => props.notificationItem.text.length > 40);

</script>

<style scoped lang="scss">
.notification {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 440px;
}

.notification > img {
  fill: white;
  color: white;
}

.close-icon-body, .close-icon-responsive {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.close-icon-body {
  display: none;
}

.notification-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 16px;
  width: 100%;
  cursor: pointer;
  z-index: $zindex-notifications;
  box-shadow: 0px 14px 34px $shadow-color;
  border-radius: 6px;
  gap: 12px;
  width: 400px;
}

.error-message {
  background-color: $error-notification-bg;
  color: $error-notification-text;
}

.success-message {
  background-color: $success-notification-bg;
  color: $success-notification-text;
}

.notification-icon {
  color: $text-color-alt;
}

.icon-body {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.icon-body.error-message {
  background-color: $error-notification-icon-color;
}

.icon-body.success-message {
  background-color: $success-notification-icon-color;
}

.text {
  overflow-wrap: break-word;
  width: 100%;
  font-weight: bold;
}

.close-icon {
  max-width: 16px;
  color: $text-color-alt;
}

.close-icon-responsive {
  display: none;
  color: $text-color-alt;
}

.notification-content.long-text {
  align-items: flex-start;
}

.notification:hover > .close-icon-body {
  display: flex;
}

@media(max-width: 769px) {
  .close-icon-body {
    display: none;
  }

  .notification {
    width: 320px;
  }

  .close-icon-responsive {
    display: flex;
    width: auto;
    height: auto;
  }

  .notification:hover > .close-icon-body {
    display: none;
  }
}

@media(max-width: 412px) {
  .notification {
    width: 100%;
  }
}
</style>
